body {
  padding: 0;
  margin: 0;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

/* fix for google autosuggestions  */
.pac-container {
  z-index: 12000 !important;
  pointer-events: auto;
}

/* disble "use two fingers" waring in google maps */
.gm-style-pbc {
  display: none !important;
}

.map-container {
}

/*  styles for loader */
@-webkit-keyframes loaderAnimation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes loaderAnimation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.8;
  }
}

.animate-loader {
  -webkit-animation: loaderAnimation 2s infinite;
  animation: loaderAnimation 2s infinite;
  pointer-events: none;
}

/* styles for "go-to-current-position" */
.show-current-location {
  text-align: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  font-family: Roboto, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  width: 34px;
  height: 30px;
  padding-top: 4px;
  line-height: 31px;
}

.show-current-location .icon.icon-location {
  width: 27px;
  height: 27px;
  background-image: url('https://gowithkid.com/app/custom-icon-location.svg');
}

/* fix style for swiper in welcome window */
#welcome-modal .swiper-container {
  height: 100%;
}
